import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Datenschutz from "../components/datenschutz";

import { Flex, Box } from "../components/FlexBox";
import { Headline, Paragraph } from "../components/parts/typography";
import { colors } from "../utilities/colors";

const Link = styled.a`
    text-decoration: none;
    color: #000;
    &:hover {
        color: ${colors.red};
    }
`;

const DatenschutzAlt = () => {
    return (
        <Layout contentTopMargin="0">
            <Flex
                flexDirection="column"
                alignSelf="flex-start"
                width="100%"
                maxWidth="872px"
                mb={["28px", "28px"]}
            >
                <Headline>Datenschutz</Headline>
                <Flex flexDirection={["column", "row"]}>
                    <Box
                        flex={["1 0 100%", "1 0 50%"]}
                        lineHeight={["16px", "22px"]}
                        pr={[0, "18px"]}
                        fontSize={["15px"]}
                    >
                        <Paragraph>
                            Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                            persönlichen Daten sehr ernst. Wir behandeln Ihre
                            personenbezogenen Daten vertraulich und entsprechend
                            der gesetzlichen Datenschutzvorschriften sowie
                            dieser Datenschutzerklärung.
                        </Paragraph>
                        <Paragraph mt="20px">
                            Die verantwortliche Stelle für die Datenverarbeitung
                            auf dieser Website ist:
                        </Paragraph>
                        <Box fontWeight={["400", "300"]} lineHeight="22px">
                            <span>IGKBERLIN MBH</span>
                            <br />
                            <span>
                                Ingenieur- und Architektengesellschaft Kaiser
                                Berlin mbH
                            </span>
                            <br />
                            <span>Holbeinstraße 41 &nbsp; D-12203 Berlin</span>
                            <br />
                            <span>
                                T +49 30 890 40 400 &nbsp; F +49 30 890 40 427
                            </span>
                            <br />
                        </Box>
                        <Link href="mailto:info@igk-berlin.de">
                            info@igk-berlin.de
                        </Link>
                        <Paragraph mt="20px">
                            Nachfolgend möchten wir Sie daher darüber
                            informieren, welche Daten bei uns erhoben und wie
                            diese von uns im Folgenden verarbeitet oder genutzt
                            werden, ebenso wie, welche begleitenden
                            Schutzmaßnahmen wir auch in technischer und
                            organisatorischer Hinsicht getroffen haben.
                        </Paragraph>
                    </Box>
                </Flex>
            </Flex>
            <Datenschutz />
        </Layout>
    );
};

export default DatenschutzAlt;
