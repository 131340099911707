import React from "react";
import styled from "styled-components";
import { typography } from "styled-system";
import { Paragraph } from "./parts/typography";
import { Flex } from "./FlexBox";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const AccordionTitle = styled.h3`
    font-weight: 600;
    line-height: 22px;
    ${typography}
`;

const Accordion = withStyles({
    root: {
        border: "none",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
            "&:first-child": {
                borderTop: 0,
            },
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderBottom: "1px solid rgb(0, 0, 0)",
        borderTop: "1px solid rgb(0, 0, 0)",
        marginBottom: -1,
        minHeight: 30,
        "&$expanded": {
            "&:not(:last-child)": {
                borderBottom: 0,
            },
            minHeight: 30,
        },
        padding: 0,
        textTransform: "uppercase",
    },
    content: {
        padding: 0,
        "&$expanded": {
            margin: "0",
        },
        margin: 0,
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: "10px 0",
        display: "flex",
        flexDirection: "column",
    },
}))(MuiAccordionDetails);

export const Datenschutz = () => {
    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Flex
            maxWidth="872px"
            flexDirection="column"
            alignSelf="flex-start"
            mb="20px"
        >
            <div>
                <Accordion
                    square
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            ERHEBUNG UND VERWENDUNG IHRER DATEN
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Alle personenbezogenen Daten, die wir von Ihnen
                            erheben, werden wir nur zu dem angegebenen Zweck
                            erheben, verarbeiten und nutzen. Dabei beachten wir,
                            dass dies nur im Rahmen der jeweils geltenden
                            Rechtsvorschriften bzw. ansonsten nur mit Ihrer
                            Einwilligung geschieht. Umfang und Art der Erhebung
                            und Verwendung Ihrer Daten unterscheidet sich
                            danach, ob Sie von uns angebotene Leistungen in
                            Anspruch nehmen oder unseren Internetauftritt nur
                            zum Abruf von Informationen besuchen.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                >
                    <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            DATENVERWENDUNG ZUR AUFGABENERFÜLLUNG
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Die Erhebung oder Verwendung Ihrer Daten erfolgt zu
                            dem Zweck, die von Ihnen gewünschte notwendige
                            Leistung zu erbringen. Ihre Daten werden zu
                            vorgenanntem Zweck ggf. an uns unterstützende
                            Dienstleister weitergegeben, die wir
                            selbstverständlich sorgfältig ausgewählt und zur
                            Einhaltung des Datenschutzrechts verpflichtet haben.
                            Dabei kann es sich insbesondere um technische
                            Dienstleister oder Versanddienstleister handeln.
                        </Paragraph>
                        <Paragraph>
                            Die Weitergabe Ihrer Daten an andere Dritte erfolgt
                            ansonsten nur, wenn dies gesetzlich erlaubt ist oder
                            wir Ihre explizite Einwilligung dafür erhalten
                            haben.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                >
                    <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            DATENSCHUTZRECHTLICHE EINWILLIGUNG
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Über die Abwicklung der von Ihnen gewünschten
                            Leistungen hinaus möchten wir gerne –
                            selbstverständlich nur, soweit Sie darin an
                            gesonderter Stelle explizit einwilligen – einen auf
                            Ihre Interessen ausgerichteten Internetauftritt
                            bieten sowie Ihnen gelegentlich per Post oder E-Mail
                            (z.B. in Form eines Newsletters) für Sie auf Basis
                            Ihrer Daten interessante Neuigkeiten und Hinweise
                            über uns zukommen lassen und Ihre Daten zur dazu
                            notwendigen Auswertung sowie ggf. zu
                            Marktforschungszwecken verwenden. Hierfür ist es
                            technisch nötig, dass wir Ihre anfallenden sowie
                            angegebenen Daten in Nutzungsprofilen zusammenfassen
                            und diese zu vorgenannten Zwecken auswerten. Dies
                            erfolgt lediglich intern und nur zu vorgenannten
                            Zwecken.
                        </Paragraph>
                        <Paragraph>
                            Für die Veröffentlichung von Bildmaterial, das Ihrem
                            Urheberrecht unterliegt oder auf dem Sie abgebildet
                            sind, benötigen wir unter Umständen ebenfalls Ihre
                            Einwilligung. Ihre Einwilligung können Sie gesondert
                            abgeben. Sie können sie im Anschluss jederzeit mit
                            Wirkung für die Zukunft widerrufen.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                >
                    <AccordionSummary
                        aria-controls="panel4d-content"
                        id="panel4d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            INTERNETNUTZUNG/SERVER-LOG-FILE
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Die Nutzung unserer Webseite ist in der Regel ohne
                            Angabe personenbezogener Daten möglich. Soweit
                            aktuell oder zukünftig auf unseren Seiten
                            personenbezogene Daten (beispielsweise Name,
                            Anschrift oder E-Mail-Adressen) erhoben werden,
                            erfolgt dies, soweit möglich, stets auf freiwilliger
                            Basis. Diese Daten werden ohne Ihre ausdrückliche
                            Zustimmung nicht an Dritte weitergegeben. Für die
                            nur informatorische Nutzung unseres
                            Internetauftritts ist es grundsätzlich nicht
                            erforderlich, dass Sie personenbezogene Daten
                            angeben. Vielmehr erheben und verwenden wir in
                            diesem Fall nur diejenigen Ihrer Daten, die uns Ihr
                            Internetbrowser automatisch übermittelt, wie etwa:
                        </Paragraph>
                        <Paragraph>
                            Datum und Uhrzeit des Abrufs einer unserer
                            Internetseiten, Ihren Browsertyp, die
                            Browsereinstellungen, das verwendete Betriebssystem,
                            die von Ihnen zuletzt besuchte Seite, die
                            übertragene Datenmenge und der Zugriffsstatus (Datei
                            übertragen, Datei nicht gefunden etc.) sowie Ihre
                            IP-Adresse.
                        </Paragraph>
                        <Paragraph>
                            Diese Daten erheben und verwenden wir bei einem
                            informatorischen Besuch ausschließlich in nicht-
                            personenbezogener Form. Eine Zusammenführung dieser
                            Daten mit anderen Datenquellen wird nicht
                            vorgenommen. Wir behalten uns vor, diese Daten
                            nachträglich zu prüfen, wenn uns konkrete
                            Anhaltspunkte für eine rechtswidrige Nutzung bekannt
                            werden. Wir weisen darauf hin, dass die
                            Datenübertragung im Internet (z.B. bei der
                            Kommunikation per E-Mail) Sicherheitslücken
                            aufweisen kann. Ein lückenloser Schutz der Daten vor
                            dem Zugriff durch Dritte ist nicht möglich.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                >
                    <AccordionSummary
                        aria-controls="panel5d-content"
                        id="panel5d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            EINSATZ VON STATISTIKTOOLS und Cookies
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Statistiktools werden von uns derzeit nicht genutzt.
                            Sollten zukünftig Statistiktools genutzt werden, so
                            wird an dieser Stelle darauf hingewiesen. Cookies
                            werden von uns derzeit nicht genutzt. Seit dem
                            Inkrafttreten der Datenschutzgrundverordnung am
                            25.05.2018 werden wir vor der Nutzung von Cookies
                            Ihre explizite Zustimmung für die Nutzung von
                            Cookies vor deren Verwendung einholen.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel6"}
                    onChange={handleChange("panel6")}
                >
                    <AccordionSummary
                        aria-controls="panel6d-content"
                        id="panel6d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            KONTAKTFORMULAR und Newsletterdaten
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Wenn Sie uns per Kontaktformular Anfragen zukommen
                            lassen, werden Ihre Angaben aus dem Anfrageformular
                            inklusive der von Ihnen dort angegebenen
                            Kontaktdaten zwecks Bearbeitung der Anfrage und für
                            den Fall von Anschlussfragen bei uns gespeichert.
                            Diese Daten geben wir nicht ohne Ihre Einwilligung
                            weiter. Einen regelmäßigen E-Mail-Newsletterdienst
                            haben wir derzeit nicht eingerrichtet. Sollten wir
                            zukünftig einen E-Mail-Newsletterdienst einrichten,
                            so können Sie einen von Ihnen bei uns abonnierten
                            Newsletter jederzeit abbestellen. Dazu können Sie
                            uns entweder eine formlose E-Mail zusenden oder über
                            den Link am Ende des Newsletters eine Stornierung
                            vornehmen.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel7"}
                    onChange={handleChange("panel7")}
                >
                    <AccordionSummary
                        aria-controls="panel6d-content"
                        id="panel6d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            DATENSICHERHEIT und Löschfristen
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Wir setzen technische und organisatorische
                            Sicherheitsmaßnahmen ein, um anfallende oder
                            erhobene personenbezogene Daten zu schützen, insb.
                            gegen zufällige oder vorsätzliche Manipulation,
                            Verlust, Zerstörung oder gegen den Angriff
                            unberechtigter Personen. Unsere Sicherheitsmaßnahmen
                            werden entsprechend der technologischen Entwicklung
                            fortlaufend verbessert.Die personenbezogene Daten,
                            die Sie an uns übermitteln, können von uns
                            gespeichert und nach den gesetzlichen Bestimmungen
                            oder mit Ihnen getroffener Vereinbarung verarbeitet
                            werden. Sofern die Nutzung und Verarbeitung der
                            Einwilligung des Nutzers oder von Dritten bedarf,
                            kann die Einwilligung jederzeit ohne Angaben von
                            Gründen widerrufen werden. In diesem kann jedoch
                            evtl. die Vertragserfüllung durch uns beeinträchtigt
                            werden.
                        </Paragraph>
                        <Paragraph>
                            Wir speichern personenbezogene Daten jeweils nur so
                            lange, bis der Zweck der Datenspeicherung entfällt,
                            so lange keine gesetzlichen Aufbewahrungsfristen
                            oder Verjährungsfristen von möglicherweise der
                            Rechtsverfolgung dienlichen Daten der Löschung
                            entgegenstehen (in diesem Fall wird die Verarbeitung
                            der Daten nach Art. 18 DSGVO eingeschränkt).
                            WIDERRUFS- UND WIDERSPRUCHSRECHT etc.
                        </Paragraph>
                        <Paragraph>
                            Nach der EU-Datenschutzgrundverordnung haben Sie
                            jederzeit ein Recht auf unentgeltliche Auskunft über
                            Ihre gespeicherten Daten sowie ggf. ein Recht auf
                            Berichtigung, Sperrung oder Löschung dieser Daten.
                            Soweit gesetzliche Anforderungen zur Erhebung von
                            Daten bestehen (z.B. Architektenliste), besteht kein
                            Widerrufsrecht.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Flex>
    );
};

export default Datenschutz;
